

@if (reservation; as r) {
  <td
    pFrozenColumn
    app-row-index-cell
    [rowIndex]="rowIndex"
    [reservation]="r"
    [isSelected]="isSelected"
    class="table-col-min-width">
    <ng-content select="[slot=select]" slot="select">
  
    </ng-content>
  </td>
  
  @for (columnItem of columnItems; track columnItems) {
    @switch (columnItem.itemType) {
      <!-- BENEFICIARY_USER -->
      @case (ReservationTableColumnItemType.BENEFICIARY_USER_NAME) {
        <td
          [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'          app-beneficiary-cell
          [beneficiaryUser]="r.beneficiaryUser"
          [hasAnyCustomerNotes]="userIdsWithAnyCustomerNotesMap[r.beneficiaryUserId]"
          [additionalUserData]="additionalUserDataMap[r.beneficiaryUserId]"
          [beneficiaryClickListener]="customerClickListener"
          [commentClickListener]="commentClickListener">
        </td>
        <!-- <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ r.beneficiaryUser | userNameOrEmail }}</span>
        </td> -->
      }
      @case (ReservationTableColumnItemType.BENEFICIARY_USER_EMAIL) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.BENEFICIARY_USER_EMAIL] }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.BENEFICIARY_USER_CERT_HOLDER) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.BENEFICIARY_USER_CERT_HOLDER] }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.BENEFICIARY_USER_CERTS) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.BENEFICIARY_USER_CERTS] }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.BENEFICIARY_USER_ROLES) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.BENEFICIARY_USER_ROLES] }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.BENEFICIARY_USER_COLLECTED_DATA) {
        <td
          [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'          app-custom-data-cell
          [value]="columnData[columnItem.id]?.value"
          [collectedAt]="columnData[columnItem.id]?.collectedAt"
          [customParams]="columnData[columnItem.id]?.customParams"
          [dataType]="columnItem.dataType">
        </td>
      }
  
      <!-- RESERVER_USER -->
      @case (ReservationTableColumnItemType.RESERVER_USER_NAME) {
        <td
          [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'          app-reserver-cell
          [reserver]="r.reserverUser"
          [hasAnyCustomerNotes]="userIdsWithAnyCustomerNotesMap[r.reserverUserId]"
          class="table-col-min-width"
          [additionalUserData]="additionalUserDataMap[r.reserverUserId]"
          [reserverClickListener]="customerClickListener"
          [commentClickListener]="commentClickListener">
        </td>
        <!-- <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ r.beneficiaryUser | userNameOrEmail }}</span>
        </td> -->
      }
      @case (ReservationTableColumnItemType.RESERVER_USER_EMAIL) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.RESERVER_USER_EMAIL] }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.RESERVER_USER_COLLECTED_DATA) {
        <td
          [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'          app-custom-data-cell
          [value]="columnData[columnItem.id]?.value"
          [collectedAt]="columnData[columnItem.id]?.collectedAt"
          [customParams]="columnData[columnItem.id]?.customParams"
          [dataType]="columnItem.dataType">
        </td>
      }
  
      <!-- SHOP_ITEM -->
      @case (ReservationTableColumnItemType.SHOP_ITEM_TITLE) {
        <td
          [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'          app-shop-item-cell
          [reservation]="r">
        </td>
        <!-- <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ r.shopItem?.title || '' }}</span>
        </td> -->
      }
      @case (ReservationTableColumnItemType.SHOP_ITEM_CODE) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.SHOP_ITEM_CODE] }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.SHOP_ITEM_ID) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.SHOP_ITEM_ID] }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.SHOP_ITEM_RESERVATION_TYPE) {
        <td
          [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'          app-type-cell
          [reservation]="r">
        </td>
      }
      @case (ReservationTableColumnItemType.SHOP_ITEM_EVENT_TITLE) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.SHOP_ITEM_EVENT_TITLE] }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.SHOP_ITEM_EVENT_CODE) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.SHOP_ITEM_EVENT_CODE] }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.SHOP_ITEM_START_AT) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.SHOP_ITEM_START_AT] | date:'d.M.YY H:mm' }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.SHOP_ITEM_END_AT) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.SHOP_ITEM_END_AT] | date:'d.M.YY H:mm' }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.SHOP_ITEM_CATEGORIES) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.SHOP_ITEM_CATEGORIES] | join:', ' }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.SHOP_ITEM_RESERVATION_STATE) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ 'ShopItemReservationState.' + columnData[ReservationTableColumnItemType.SHOP_ITEM_RESERVATION_STATE] | translate }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.SHOP_ITEM_INTERNAL_TAGS) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ (columnData[ReservationTableColumnItemType.SHOP_ITEM_INTERNAL_TAGS] || []) | join:', ' }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.SHOP_ITEM_CUSTOM_ITEM_NUMBER) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.SHOP_ITEM_CUSTOM_ITEM_NUMBER] }}</span>
        </td>
      }
  
      <!-- RESERVATION -->
      @case (ReservationTableColumnItemType.RESERVATION_STATE) {
        <td
          [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'          app-tag-cell
          [reservation]="r">
        </td>
      }
      @case (ReservationTableColumnItemType.RESERVATION_CREATED_AT) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.RESERVATION_CREATED_AT] | date:'d.M.YY H:mm' }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.RESERVATION_CANCELLED_AT) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.RESERVATION_CANCELLED_AT] | date:'d.M.YY H:mm' }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.RESERVATION_APPROVED_AT) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.RESERVATION_APPROVED_AT] | date:'d.M.YY H:mm' }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.RESERVATION_CONFIRMED_AT) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.RESERVATION_CONFIRMED_AT] | date:'d.M.YY H:mm' }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.RESERVATION_GUEST) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ (columnData[ReservationTableColumnItemType.RESERVATION_GUEST] | bool) | translate }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.RESERVATION_QUANTITY) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.RESERVATION_QUANTITY] }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.RESERVATION_COLLECTED_DATA) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <ng-template #htmlContentResData>
            <p-table [value]="columnData[ReservationTableColumnItemType.RESERVATION_COLLECTED_DATA]">
              <ng-template pTemplate="body" let-item>
                <tr>
                  <td class="table-col-min-width">
                    {{ item.title }}
                  </td>
                  <td class="table-col-min-width">
                    {{ item.value }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </ng-template>
          @if (columnData[ReservationTableColumnItemType.RESERVATION_COLLECTED_DATA].length > 0) {
            <div class="flex flex-column" [ngbTooltip]="htmlContentResData" tooltipClass="transparent-tooltip">
              <span>{{ columnData[ReservationTableColumnItemType.RESERVATION_COLLECTED_DATA].length }} {{ columnData[ReservationTableColumnItemType.RESERVATION_COLLECTED_DATA].length | inflection:'utils.value-string' | translate }}</span>
            </div>
          }
        </td>
      }
      @case (ReservationTableColumnItemType.RESERVATION_ADMIN_NOTE) {
        @if (reservation?.id) {
          <td
            app-admin-note-cell
            [adminNote]="columnData[ReservationTableColumnItemType.RESERVATION_ADMIN_NOTE]"
            [reservationId]="reservation!.id"
            [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'
          >
          </td>
        }
      }
      @case (ReservationTableColumnItemType.RESERVATION_APPLIED_DISCOUNTS) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <ng-template #htmlContentDiscountData>
            <p-table [value]="columnData[ReservationTableColumnItemType.RESERVATION_APPLIED_DISCOUNTS]">
              <ng-template pTemplate="header">
                <tr>
                  <th class="table-col-min-width">
                    {{ 'admin.reservation-table.applied-discounts.table.title.label' | translate }}
                  </th>
                  <th class="table-col-min-width">
                    {{ 'admin.reservation-table.applied-discounts.table.code.label' | translate }}
                  </th>
                  <th class="table-col-min-width">
                    {{ 'admin.reservation-table.applied-discounts.table.percent-value.label' | translate }}
                  </th>
                  <th class="table-col-min-width">
                    {{ 'admin.reservation-table.applied-discounts.table.absolute-value.label' | translate }}
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-item>
                <tr>
                  <td class="table-col-min-width">
                    {{ item.title }}
                  </td>
                  <td class="table-col-min-width">
                    {{ item.code }}
                  </td>
                  <td class="table-col-min-width">
                    {{ item.percentValue | percent:'1.0-4' }}
                  </td>
                  <td class="table-col-min-width">
                    {{ [{ value: item.absoluteValue, currency: item.currency }] | price | async }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </ng-template>
          @if (columnData[ReservationTableColumnItemType.RESERVATION_APPLIED_DISCOUNTS].length > 0) {
            <div class="flex flex-column" [ngbTooltip]="htmlContentDiscountData" tooltipClass="transparent-tooltip">
              <span>{{ columnData[ReservationTableColumnItemType.RESERVATION_APPLIED_DISCOUNTS].length }} {{ columnData[ReservationTableColumnItemType.RESERVATION_APPLIED_DISCOUNTS].length | inflection:'utils.value-string' | translate }}</span>
            </div>
          }
        </td>
      }
  
      <!-- ORDER -->
      @case (ReservationTableColumnItemType.ORDER_NUMBER) {
        <td
          [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'          app-order-cell
          [reservation]="reservation"
          (onOrder)="onOrder($event)"
          (onWaitingForOrder)="onWaitingForOrder($event)">
        </td>
      }
      @case (ReservationTableColumnItemType.ORDER_CREATED_AT) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.ORDER_CREATED_AT] | date:'d.M.YY H:mm' }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.ORDER_COMPLETED_AT) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.ORDER_COMPLETED_AT] | date:'d.M.YY H:mm' }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.ORDER_CANCELLED_AT) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.ORDER_CANCELLED_AT] | date:'d.M.YY H:mm' }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.ORDER_MATURITY) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.ORDER_MATURITY] | date:'d.M.YY H:mm' }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.ORDER_INVOICE_NUMBER) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.ORDER_INVOICE_NUMBER] }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.ORDER_PROFORMA_NUMBER) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.ORDER_PROFORMA_NUMBER] }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.ORDER_BILLING_INFO) {
        <td
          [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'          app-custom-data-cell
          [value]="columnData[ReservationTableColumnItemType.ORDER_BILLING_INFO]"
          [collectedAt]="null"
          [customParams]="null"
          [dataType]="DataType.BILLING_INFO">
        </td>
      }
      @case (ReservationTableColumnItemType.ORDER_INVOICING_CONTACT) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.ORDER_INVOICING_CONTACT] }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.ORDER_FINAL_PRICE) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.ORDER_FINAL_PRICE] }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.ORDER_CUSTOMER_NOTE) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.ORDER_CUSTOMER_NOTE] }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.ORDER_CURRENCY) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.ORDER_CURRENCY] }}</span>
        </td>
      }
  
      <!-- ORDER_ITEM -->
      @case (ReservationTableColumnItemType.ORDER_ITEM_PRICE) {
        <td
          [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'          app-price-cell
          [reservation]="reservation"
          >
        </td>
      }
      @case (ReservationTableColumnItemType.ORDER_ITEM_VAT) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ (columnData[ReservationTableColumnItemType.ORDER_ITEM_VAT] ?? 0) | percent }}</span>
        </td>
      }
  
      <!-- COMPANY -->
      @case (ReservationTableColumnItemType.COMPANY_NUMBER) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.COMPANY_NUMBER] }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.COMPANY_NAME) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.COMPANY_NAME] }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.COMPANY_ADDRESS) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.COMPANY_ADDRESS] }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.COMPANY_EMAIL) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.COMPANY_EMAIL] }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.COMPANY_PHONE) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.COMPANY_PHONE] }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.COMPANY_WEBSITE) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.COMPANY_WEBSITE] }}</span>
        </td>
      }
      @case (ReservationTableColumnItemType.COMPANY_CUSTOM_DATA) {
        <td [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'>
          <span>{{ columnData[ReservationTableColumnItemType.COMPANY_CUSTOM_DATA] }}</span>
        </td>
      }
    }
  }
  
  <!-- <td
    [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'    app-reserver-cell
    [reserver]="r.reserverUser"
    [hasAnyCustomerNotes]="userIdsWithAnyCustomerNotesMap[r.reserverUserId]"
    class="table-col-min-width"
    [additionalUserData]="additionalUserDataMap[r.reserverUserId]"
    [reserverClickListener]="customerClickListener"
    [commentClickListener]="commentClickListener">
  </td>
  
  <td
    [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'    app-beneficiary-cell
    [beneficiaryUser]="r.beneficiaryUser"
    [hasAnyCustomerNotes]="userIdsWithAnyCustomerNotesMap[r.beneficiaryUserId]"
    [additionalUserData]="additionalUserDataMap[r.beneficiaryUserId]"
    [beneficiaryClickListener]="customerClickListener"
    [commentClickListener]="commentClickListener">
  </td>
  
  <td
    [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'    *ngIf="showShopItemColumn"
    app-shop-item-cell
    [reservation]="r">
  </td>
  
  <td
    [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'    *ngIf="showTypeColumn"
    app-type-cell
    [reservation]="r">
  </td>
  
  <td
    [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'    app-price-cell
    [reservation]="reservation"
    >
  </td>
  
  <td
    [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'    app-order-cell
    [reservation]="reservation"
    (onOrder)="onOrder($event)"
    (onWaitingForOrder)="onWaitingForOrder($event)">
  </td>
  
  <td
    [style]='{"max-width": columnItem.width ? (columnItem.width + "px !important") : "215px", "width": columnItem.width ? (columnItem.width + "px !important") : null}'    app-tag-cell
    [reservation]="r">
  </td> -->
  
  <td class="table-col-min-width" alignFrozen="right" pFrozenColumn style="right: -1px !important;">
    @if (menuItems.length) {
      <button (click)="itemMenu.toggle($event)" pButton pRipple type="button" icon="pi pi-ellipsis-h" class="p-button-text p-button-plain p-button-rounded flex-shrink-0"></button>
      <p-menu #itemMenu [popup]="true" [model]="menuItems" appendTo="body"></p-menu>
    }
  </td>

}

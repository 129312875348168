import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ModalHeaderComponent } from '../components/modal-header/modal-header.component';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { IShopItem } from '../../models/shop-item/shop-item.model';
import { AutoCompleteModule, AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { IUser } from '../../models/user/user.model';
import { SelectedOrgService } from '../../services/selected-org.service';
import { UsersService } from '../../services/entities/users/users.service';
import { finalize, Subscription, take } from 'rxjs';
import { UtilsService } from '../../services/utils.service';
import { Modal } from '../modal';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { emailValidator } from '../../utils/session-group-form-utils/validators';
import { UserHelperService } from '../../services/helpers/user-helper.service';
import { ICallablesReservationsChangeBeneficiaryData } from '../../services/entities/reservations/reservations.service';
import { Language } from '../../enums/utils/languages.enum';
import { DropdownModule } from 'primeng/dropdown';
import { SelectItem } from 'primeng/api';
import { CustomDataInputsComponent } from 'src/app/pages/admin/components/custom-data-inputs/custom-data-inputs.component';
import { GetCustomDataResultType } from '../../services/entities/users/customer/customers.service';
import { AcceptationRequirementTemplateCode } from '../../models/collecting-data/acceptation-requirement.model';

export interface IChangeBeneficiaryModalResult extends Omit<ICallablesReservationsChangeBeneficiaryData, 'reservationId'> {}

@Component({
  selector: 'app-change-beneficiary-modal',
  standalone: true,
  imports: [
    CommonModule, ModalHeaderComponent, ReactiveFormsModule, AutoCompleteModule,
    TranslateModule, ButtonModule, InputTextModule, DropdownModule, CustomDataInputsComponent
  ],
  templateUrl: './change-beneficiary-modal.component.html',
  styleUrl: './change-beneficiary-modal.component.scss'
})
export class ChangeBeneficiaryModalComponent extends Modal implements OnInit, OnDestroy {
  @Input({required: true}) shopItem!: IShopItem;
  @Output() result = new EventEmitter<IChangeBeneficiaryModalResult>();

  form = new FormGroup({
    selectedUser: new FormControl<IUser | null>(null),
    userName: new FormControl<string | null>(null, { nonNullable: true, validators: [Validators.required] }),
    preferredLanguage: new FormControl<Language | null>(null, { nonNullable: true, validators: [Validators.required] }),
    email: new FormControl<string>('', { validators: [Validators.required, emailValidator] })
  });

  customDataFG = new FormGroup<{ [id: number]: FormControl<any>; }>({});

  fetchingUserData: boolean = false;
  userData: GetCustomDataResultType[] = [];

  preferredLanguageOptions: SelectItem[] = Object.entries(Language).map(([key, value]) => ({
    value,
    label: `Language.${key}`
  }));

  userSuggestions: IUser[] = [];
  subs: Subscription[] = [];



  constructor(
    private selectedOrgService: SelectedOrgService,
    private usersService: UsersService,
    private utilsService: UtilsService,
    private uHelper: UserHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.push(this.form.controls.email.valueChanges.subscribe((e) => {
      this.form.controls.userName.setValue(null);
      this.form.controls.userName.enable();
      this.form.controls.preferredLanguage.setValue(null);
      this.form.controls.preferredLanguage.enable();
      Object.values(this.customDataFG.controls).forEach((control) => {
        control.setValue(undefined);
        control.enable();
      });
      this.userData.forEach((d) => d.value = undefined);
      this.form.updateValueAndValidity();
    }));
    const userData: GetCustomDataResultType[] = this.shopItem.formSettings.map((formSetting) => {
      return {
        ...formSetting,
        id: formSetting.collectedUserDataId || formSetting.id,
        value: null,
        for: 'userCard',
        params: formSetting
      };
    });
    this.customDataFG = this.utilsService.createCustomDataFG(userData, true);
    this.userData = userData;
  }

  whisper(e: { originalEvent: Event, query: string }) {
    const orgId = this.selectedOrgService.getCurrentValue();
    if (!orgId) return;
    this.usersService.whisper({
        query: e.query,
        onlyEmail: true,
        organizationIdCustomer: orgId
    }).pipe(
      take(1),
    ).subscribe({
      next: (res) => {
        this.userSuggestions = res.map(x => ({ ...x, label: `${x.name ?? ''} ${x.surname ?? ''} ${x.email}` }));
      }
    });
  }

  onUserSuggestionSelect(e: AutoCompleteSelectEvent) {
    this.form.controls.selectedUser.setValue(e.value);
    this.form.controls.email.setValue(e.value.email, { emitEvent: false });
    this.form.controls.userName.setValue(this.uHelper.getFullName(e.value));
    this.form.controls.userName.disable();
    this.form.controls.preferredLanguage.setValue(e.value.preferredLanguage);
    this.form.controls.preferredLanguage.disable();
    this.fetchingUserData = true;
    this.usersService.getUserData({
      organizationId: this.selectedOrgService.getCurrentValue()!,
      userId: e.value.id
    }).pipe(
      take(1),
      finalize(() => this.fetchingUserData = false)
    ).subscribe((userData) => {
      userData.forEach((d) => {
        const foundData = this.userData.find((dd) => d.id === dd.id);
        if (foundData) foundData.value = d.value;
        const foundControl = this.customDataFG.get(d.id.toString());
        if (foundControl && (d.value !== null && d.value !== undefined)) {
          foundControl.setValue(d.value);
          foundControl.disable();
        }
      });
    });
    this.form.updateValueAndValidity();
  }

  onSubmit() {
    this.utilsService.markFormGroupDirty(this.form);
    if (this.form.invalid) {
      console.error('invalid');
      return;
    }
    const formData = this.form.getRawValue();
    const customData = this.customDataFG.getRawValue();
    this.result.emit({
      beneficiaryUserId: formData.selectedUser?.id || null,
      beneficiaryUser: {
        customData: Object.entries(customData).reduce((prev, [id, value]) => ({
          ...prev,
          [id]: {
            value,
            collectedAt: new Date()
          }
        }), {}),
        preferredLanguage: formData.preferredLanguage!,
        email: formData.email!,
        fullName: formData.userName!
      },
    });
    this.close();
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}



<div
  class="row">
  <span class="flex flex-nowrap align-items-baseline column-gap-1">
    <span class="font-bold name-ellipsis" [class.clickable]="reserverClickListener" (click)="reserverClickListener ? reserverClickListener.next(reserver) : null">
      {{ reserver?.name }} {{ reserver?.surname }}
    </span>

    @if (hasAnyCustomerNotes) {
      <i
        (click)="commentClickListener ? commentClickListener.next(reserver) : null"
        [class.cursor-pointer]="commentClickListener"
        class="pi pi-comment text-bluegray-200 text-sm">
      </i>
    }
  </span>
</div>

<div *ngIf="reserver?.email as email" class="row">
  <span
    [appTooltipCopy]="email"
    class="email-ellipsis cursor-pointer">
    {{ email }}
  </span>
</div>

<div *ngIf="additionalUserData?.phoneNumber as phoneNumber" class="row">
  <span
    [appTooltipCopy]="phoneNumber"
    class="cursor-pointer">
    {{ phoneNumber }}
  </span>
</div>
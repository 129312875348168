import { Injectable } from '@angular/core';
import { CallableNames, DbService } from '../../db.service';
import { Observable } from 'rxjs';
import { IInvoice, IInvoiceItem, InvoiceTypeDB } from 'src/app/shared/models/invoice/invoice.model';
import { IOrder } from 'src/app/shared/models/order/order-model';
import { ApiType } from 'src/app/shared/enums/api/api-type.enum';
import { IAddress } from 'src/app/shared/modals/address-form-group-modal/address-form-group-modal.component';
import { OrderEvent } from 'src/app/pages/admin/org-admin/events/admin-event/components/shop-item-modules/shop-item-module-price-invoicing/si-maturity-start-input/si-maturity-start-input.component';
import { IOrganization } from 'src/app/shared/models/organization/organization.model';
import { IInvoiceProfile } from 'src/app/shared/models/invoice-profile/invoice-profile.model';
import { IUser } from 'src/app/shared/models/user/user.model';
import { IShopItemCustomSettings } from 'src/app/shared/models/shop-item/shop-item-custom-settings.model';
import { ISession } from 'src/app/shared/models/session-group/session.model';
import { ShopItemInvoicingMode } from 'src/app/shared/enums/shop-item/shop-item-invoicing-mode.enum';
import { IOrgCustomDomainsVerifiedFE } from 'src/app/shared/models/organization/custom-domain.model';
import { IInvoiceFE } from 'src/app/pages/admin/org-admin/payments-tabs/invoices/invoices.component';

export interface ICallablesInvoicesGetByOrderData {
  orderUuid: string;
  includeOrderItems?: boolean;
};

interface ICallablesInvoicesGetCreditNoteData {
  creditNoteUuid: string;
}

export interface IAdditionalInvoiceInfo {
  shopItemCustomSettings: IShopItemCustomSettings;
  invoicingMode: ShopItemInvoicingMode;
  firstSession: ISession | null;
  proFormaInvoiceTitle: string | null;
  bankAccountNumber: string | null;
  variableSymbol: string | null;
}

export interface ICallablesInvoicesGetByOrderResult {
  invoice: (IInvoiceOrderType & { order: IOrder }) | null;
  proFormaInvoice: (IInvoiceOrderType & { order: IOrder }) | null;
  creditNotes: IInvoiceOrderType[];
  taxReceiptUuids: string[];
  additionalInfo: IAdditionalInvoiceInfo | null;
}

export interface IInvoicesCanModifyData {
  invoiceIds: number[];
  invoiceUuids: string[];
}

export interface IInvoicesModifyData {
  invoiceId: number;

  // payee
  legalName: string;
  address: string;
  companyNumber: string;
  vatNumber: string | null;
  vatPayer: boolean;
  apiType: ApiType;

  // payer
  payerName: string;
  payerCompanyBillingInfo: {
    name: string | null;
    ico: string | null;
    vatNumber: string | null;
    address: IAddress;
  };

  invoiceNumber: string;
  invoiceFooterText: string | null;
  text: string | null;
  taxableTransactionDate: string | null;
  createdAt: string;
  maturity: string;
  paidAt: string | null;

  // order items
  invoiceItems: {
    id: number;
    title: string;
    count: number;
    vat: number;
    unitPriceWithVat: number;
    unitPriceWithoutVat: number;
  }[];
}

export interface IInvoiceInclude {
  order: boolean;
  orderUser: boolean;
  orderItems: boolean;
}

export interface IInvoicesGetManyByOrgData {
  organizationId: number;
  include: IInvoiceInclude;
}

export type IInvoiceOrderType = (
  IInvoice & {
    order: (IOrder & { organization: (IOrganization & { invoiceProfile: IInvoiceProfile }); user: IUser; });
  }
);

interface ICallablesInvoicesIssueData {
  modifyData: IInvoicesModifyData;
  orderId: number;
  invoiceType: InvoiceTypeDB
}

interface ICallablesInvoicesIssueResult {
  affectedReservationIds: number[];
  orderUuid: string;
  orgCustomDomains: IOrgCustomDomainsVerifiedFE;
}

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {

  constructor(
    private dbService: DbService,
  ) { }

  public getManyByOrg(data: IInvoicesGetManyByOrgData): Observable<IInvoiceFE[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesInvoicesGetManyByOrg, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getById(invoiceId: number): Observable<IInvoice> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesInvoicesGetById, data: { invoiceId } });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getByOrder(data: ICallablesInvoicesGetByOrderData): Observable<ICallablesInvoicesGetByOrderResult> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesInvoicesGetByOrder, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getCreditNote(data: ICallablesInvoicesGetCreditNoteData): Observable<IInvoiceOrderType | null> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesInvoicesGetCreditNote, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public modify(data: IInvoicesModifyData): Observable<IInvoice> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesInvoicesModify, data });
    return this.dbService.handleObs(obs);
  }

  public create(proFormaInvoiceId: number): Observable<IInvoice> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesInvoicesCreate, data: { proFormaInvoiceId } });
    return this.dbService.handleObs(obs);
  }

  public canModify(data: IInvoicesCanModifyData): Observable<boolean> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesInvoicesCanModify, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getPlaceholderData(orderId: number): Observable<IInvoice> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesInvoicesGetPlaceholderData, data: { orderId } });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public issueNewInvoice(data: ICallablesInvoicesIssueData): Observable<ICallablesInvoicesIssueResult> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesInvoicesIssue, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }
}

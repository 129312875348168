import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { ModalHeaderComponent } from '../components/modal-header/modal-header.component';
import { Modal } from '../modal';
import { UtilsService } from '../../services/utils.service';
import { CountryDropdownInputComponent } from '../../components/country-dropdown-input/country-dropdown-input.component';
import { TranslateModule } from '@ngx-translate/core';

export interface IAddress {
  buildingNumber: string;
  street: string;
  city: string;
  zip: string;
  country: string;
  countryCode: string;
};

export interface IAddressFG {
  buildingNumber: FormControl<string>;
  street: FormControl<string>;
  city: FormControl<string>;
  zip: FormControl<string>;
  country: FormControl<string>;
  countryCode: FormControl<string>;
};

export const getAddressFG = ({ address, required = false }: { address?: IAddress, required?: boolean }) => {
  const commonValidators = required ? [ Validators.required ] : [];
  return new FormGroup<IAddressFG>({
    buildingNumber: new FormControl(address?.buildingNumber || '', { nonNullable: true, validators: [ ...commonValidators ] }),
    street: new FormControl(address?.street || '', { nonNullable: true, validators: [ ...commonValidators ] }),
    city: new FormControl(address?.city || '', { nonNullable: true, validators: [ ...commonValidators ] }),
    zip: new FormControl(address?.zip || '', { nonNullable: true, validators: [ ...commonValidators ] }),
    country: new FormControl(address?.country || '', { nonNullable: true, validators: [ ...commonValidators ] }),
    countryCode: new FormControl(address?.countryCode || '', { nonNullable: true, validators: [ ...commonValidators ] }),
  });
};

@Component({
  selector: 'app-address-form-group-modal',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule,
    InputTextModule, ButtonModule,
    ModalHeaderComponent, CountryDropdownInputComponent, TranslateModule
  ],
  templateUrl: './address-form-group-modal.component.html',
  styleUrls: ['./address-form-group-modal.component.scss']
})
export class AddressFormGroupModalComponent extends Modal implements OnInit {

  @Input() FG: FormGroup<IAddressFG> | undefined;

  internalFG = getAddressFG({});

  constructor(
    private utilsSerivce: UtilsService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.FG) {
      this.internalFG.setValue(this.FG.getRawValue());
    }
  }

  onSubmit() {
    this.utilsSerivce.markFormGroupDirty(this.internalFG);
    if (this.internalFG.invalid) {
      console.error('invalid');
      return;
    };

    this.FG?.setValue(this.internalFG.getRawValue());
    this.close();
  }

}

import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, finalize, of, take, tap } from 'rxjs';
import { IReservation } from '../../models/reservation/reservation.model';
import { IOrderCancelData, IOrderSetExpirationData, OrdersService } from '../entities/orders/orders.service';
import { ICallablesReservationsChangeBeneficiaryData, ICustomDataResult, IGetReservationsForTableByOrgResult, IReservationsApproveSubmittedData, IReservationsApproveWaitingData, IReservationsCancelData, IReservationsConfirmAsGuestData, IReservationsConfirmData, IReservationsSetExpirationData, IReservationsSetWaitingData, ReservationsService } from '../entities/reservations/reservations.service';
import { SelectedOrgService } from '../selected-org.service';
import { UtilsService } from '../utils.service';
import { ICallablesOffersCancelData, OffersService } from '../entities/offers/offers.service';
import { StoreService } from './store.service';
import { ReservationState } from '../../enums/reservation/reservation-states.enum';
import { OrderState } from '../../enums/order/order-states.enum';
import { ShopItemState } from '../../enums/shop-item/shop-item-states.enum';
import { DownloadModalComponent } from '../../modals/download-modal/download-modal.component';
import { ModalService } from '../modal.service';
import { IOrderItemPriceUpdateData, OrderItemsService } from '../entities/order-items/order-items.service';
import { IShopItemCombinedStates } from 'src/app/pages/admin/org-admin/reservations/reservation-table/items-filter-modal/items-filter-modal.component';
import { InvoicesService } from '../entities/invoices/invoices.service';
import { IInvoice } from '../../models/invoice/invoice.model';

export interface IReservationsCustomDataMap {
  [reservationId: number]: ICustomDataResult[];
}

export interface IAdditionalUserDataMap {
  [userId: number]: IAdditionalUserData | undefined
}

export interface IAdditionalUserData {
  birthDate: Date | null;
  phoneNumber: string | null;
}

export interface IOrgReservationsState {
  reservations$: BehaviorSubject<IReservation[]>,
  paginationTotalCount$: BehaviorSubject<number>,
  totalCount$: BehaviorSubject<number>,
  reservationsColumnDataMap$: BehaviorSubject<{ [reservationId: number]: { [key: string]: any; }; }>,
  reservationsCustomDataMap$: BehaviorSubject<IReservationsCustomDataMap>,
  additionalUserDataMap$: BehaviorSubject<IAdditionalUserDataMap>,
  userIdsWithAnyCustomerNotesMap$: BehaviorSubject<{[key: number]: boolean}>,
  fetchingReservations$: BehaviorSubject<boolean>,
  updatingReservations$: BehaviorSubject<number[]>,
}

interface IInitData {
  shopItemId?: number;
  eventId?: number;

  filters?: IOrgReservationFilters;

  // ordering
  order?: {
    sortField: string;
    sortOrder: number;
  };

  limit: number;
  offset: number;
  
  export?: boolean;
}

export interface IOrgReservationFilters {
  searchQuery?: string | null;
  reservationStates?: ReservationState[];
  orderStates?: OrderState[];
  shopItemStates?: IShopItemCombinedStates;
  shopItemIds?: number[];
  publicTagIds?: number[];
  categoryIds?: number[];
  internalTagIds?: number[];
}

@Injectable({
  providedIn: 'root'
})
export class OrgReservationsStoreService implements OnDestroy {

  private initState: IOrgReservationsState = {
    reservations$: new BehaviorSubject<IReservation[]>([]),
    paginationTotalCount$: new BehaviorSubject<number>(0),
    totalCount$: new BehaviorSubject<number>(0),
    reservationsCustomDataMap$: new BehaviorSubject<IReservationsCustomDataMap>({}),
    additionalUserDataMap$: new BehaviorSubject<IAdditionalUserDataMap>({}),
    reservationsColumnDataMap$: new BehaviorSubject<{ [reservationId: number]: { [key: string]: any; }; }>({}),
    userIdsWithAnyCustomerNotesMap$: new BehaviorSubject<{[key: number]: boolean}>({}),
    fetchingReservations$: new BehaviorSubject<boolean>(false),
    updatingReservations$: new BehaviorSubject<number[]>([]),
  };
  public state = this.utilsService.initializeState(this.initState) as IOrgReservationsState;

  initData: IInitData | undefined;

  private subs: Subscription[] = [];

  constructor(
    private utilsService: UtilsService,
    private selectedOrgService: SelectedOrgService,
    private reservationsService: ReservationsService,
    private ordersService: OrdersService,
    private offersService: OffersService,
    private store: StoreService,
    private modalService: ModalService,
    private orderItemsService: OrderItemsService,
    private invoiceService: InvoicesService
  ) {
    this.subs.push(
      this.store.actions.orgReservation_reservationChangedShopItem$.subscribe((id) => {
        // todo nedelat cely reinit, pouze nacist novou a starou smazat
        this.reinit();
      })
    );
  }

  public reinit() {
    if (!this.initData) return;
    this.init(this.initData);
  }

  public init(initData: IInitData) {
    this.initData = initData;

    this.utilsService.resetState(this.initState, this.state);

    this.state.fetchingReservations$.next(true);
    this.getReservations(this.initData).pipe(
      take(1),
      finalize(() => this.state.fetchingReservations$.next(false))
    ).subscribe({
      next: (res) => {
        const result = <IGetReservationsForTableByOrgResult>res;
        this.state.reservations$.next(result.reservations);
        this.state.paginationTotalCount$.next(result.pagination.totalCount);
        this.state.totalCount$.next(result.totalCount);
        this.state.reservationsColumnDataMap$.next(result.reservationsColumnDataMap);
        this.state.reservationsCustomDataMap$.next(result.reservationsCustomDataMap);
        this.state.additionalUserDataMap$.next(result.additionalUsersDataMap);
        this.state.userIdsWithAnyCustomerNotesMap$.next(result.userIdsWithAnyCustomerNotesMap);
      }
    });
  }

  public exportReservations(columnIds: string[]) {
    const orgId = this.selectedOrgService.getCurrentValue();
    if (!this.initData || !orgId) return;

    const downloadUrl$ = new BehaviorSubject<string | null>(null);
    const preparingLink$ = new BehaviorSubject<boolean>(true);
    this.modalService.openDownloadModal(DownloadModalComponent, downloadUrl$, preparingLink$);

    this.reservationsService.getForTableByOrg({
      orgId: orgId,
      ...this.initData,
      export: true,
      exportColumnIds: columnIds
    }).subscribe({
      next: (link) => {
        downloadUrl$.next(<string>link);
        preparingLink$.next(false);
      }
    });
  }

  public cancelOffer(data: ICallablesOffersCancelData, reservationId: number) {
    const reservationIds = [reservationId];
    this.addUpdatingToState(reservationIds);
    return this.offersService.cancel(data).pipe(
      take(1),
      tap(() => {
        this.updateReservationsInState(reservationIds);
      })
    );
  }

  public cancel(data: IReservationsCancelData) {
    const reservationIds = data.data.map(x => x.reservationId);
    if (data.partnerReservationId) reservationIds.push(data.partnerReservationId);
    this.addUpdatingToState(reservationIds);
    return this.reservationsService.cancel(data).pipe(
      take(1),
      tap(() => {
        this.updateReservationsInState(reservationIds);
      })
    );
  }

  public changeBeneficiary(data: ICallablesReservationsChangeBeneficiaryData) {
    const reservationIds = [data.reservationId];
    this.addUpdatingToState(reservationIds);
    return this.reservationsService.changeBeneficiary(data).pipe(
      take(1),
      tap(() => {
        this.updateReservationsInState(reservationIds);
      })
    );
  }

  public confirm(data: IReservationsConfirmData[]) {
    const reservationIds = data.map(x => x.reservationId);
    this.addUpdatingToState(reservationIds);
    return this.reservationsService.confirm(data).pipe(
      take(1),
      tap(() => {
        this.updateReservationsInState(reservationIds)
      })
    );
  }

  public setWaiting(data: IReservationsSetWaitingData[]) {
    const reservationIds = data.map(x => x.reservationId);
    this.addUpdatingToState(reservationIds);
    return this.reservationsService.setWaiting(data).pipe(
      take(1),
      tap(() => {
        this.updateReservationsInState(reservationIds)
      })
    );
  }

  public confirmAsGuest(data: IReservationsConfirmAsGuestData) {
    this.addUpdatingToState([data.reservationId]);
    return this.reservationsService.confirmAsGuest(data).pipe(
      take(1),
      tap(() => {
        this.updateReservationsInState([data.reservationId])
      })
    );
  }

  public approveWaiting(data: IReservationsApproveWaitingData[]) {
    const reservationIds = data.map(x => x.reservationId);
    this.addUpdatingToState(reservationIds);
    return this.reservationsService.approveWaiting(data).pipe(
      take(1),
      tap(() => {
        this.updateReservationsInState(reservationIds)
      })
    );
  }

  public approveSubmitted(data: IReservationsApproveSubmittedData[]) {
    const reservationIds = data.map(x => x.reservationId);
    this.addUpdatingToState(reservationIds);
    return this.reservationsService.approveSubmitted(data).pipe(
      take(1),
      tap(() => {
        this.updateReservationsInState(reservationIds)
      })
    );
  }

  public setReservationExpiration(data: IReservationsSetExpirationData[]) {
    const reservationIds = data.map(x => x.reservationId);
    this.addUpdatingToState(reservationIds);
    return this.reservationsService.setExpiration(data).pipe(
      take(1),
      tap(() => {
        this.updateReservationsInState(reservationIds)
      })
    );
  }

  public cancelOrders(data: IOrderCancelData[]) {
    const orderIds = data.map(x => x.orderId);
    // id rezervaci, ktere jsou v techto objednavkach
    const reservationIds = this.state.reservations$.getValue().filter(x => orderIds.includes( x.orderId! )).map(x => x.id);
    this.addUpdatingToState(reservationIds);
    return this.ordersService.cancelOrders(data).pipe(
      take(1),
      tap(() => {
        this.updateReservationsInState(reservationIds)
      })
    );
  }

  public setOrderExpiration(data: IOrderSetExpirationData[]) {
    const orderIds = data.map(x => x.orderId);
    // id rezervaci, ktere jsou v techto objednavkach
    const reservationIds = this.state.reservations$.getValue().filter(x => orderIds.includes( x.orderId! )).map(x => x.id);
    this.addUpdatingToState(reservationIds);
    return this.ordersService.setExpirations(data).pipe(
      take(1),
      tap(() => {
        this.updateReservationsInState(reservationIds)
      })
    );
  }

  public restoreCancelledReservation(reservationId: number, onlyIndividual: boolean) {
    this.addUpdatingToState([reservationId]);
    return this.reservationsService.restoreCancelled(reservationId, onlyIndividual).pipe(
      take(1),
      tap((value) => {
        this.removeUpdatingFromState([reservationId]);
        this.reinit();
      })
    );
  }

  public restoreCancelledOrder(reservationId: number, orderId: number) {
    this.addUpdatingToState([reservationId]);
    return this.ordersService.restoreOrder(orderId).pipe(
      take(1),
      tap((value) => {
        this.removeUpdatingFromState([reservationId]);
        this.reinit();
      })
    );
  }

  public updateReservationPrice(data: IOrderItemPriceUpdateData) {
    this.addUpdatingToState([data.reservationId]);
    return this.orderItemsService.updatePrice(data).pipe(
      take(1),
      tap(() => {
        this.removeUpdatingFromState([data.reservationId]);
        this.reinit();
      })
    );
  }

  public getInvoicePlaceholder(reservationId: number, orderId: number): Observable<IInvoice> {
    this.addUpdatingToState([reservationId]);
    return this.invoiceService.getPlaceholderData(orderId).pipe(
      take(1),
      tap((res) => {
        this.removeUpdatingFromState([reservationId]);
        return res;
      })
    );
  }

  public updateReservationsInState(reservationIds: number[]) {
    if (!this.initData) return;
    this.addUpdatingToState(reservationIds);
    this.getReservations(this.initData).pipe(
      take(1),
      finalize(() => this.removeUpdatingFromState(reservationIds)),
    ).subscribe({
      next: (res) => {
        const result = <IGetReservationsForTableByOrgResult>res;

        const updatedReservations = result.reservations.filter(x => reservationIds.includes(x.id));
        const currReservations = [...this.state.reservations$.getValue()];

        // update undirectly changed reservations (by state and order.state)
        for (let i = 0; i < currReservations.length; i++) {
          const currReservation = currReservations[i];
          const index = result.reservations.findIndex(x => x.id === currReservation.id && (x.state !== currReservation.state || x.order?.state !== currReservation.order?.state ));
          if (index >= 0) {
            currReservations[i] = result.reservations[index];
          }
        }

        // update directly changed reservations in state
        for (let updatedReservation of updatedReservations) {
          const index = currReservations.findIndex(x => x.id === updatedReservation.id); 
          if (index >= 0) {
            currReservations[index] = updatedReservation;
          }
        }
        this.state.reservations$.next(currReservations);
      }
    });
  }

  private addUpdatingToState(ids: number[]) {
    this.state.updatingReservations$.next( [ ...this.state.updatingReservations$.getValue(), ...ids ] );
  }
  private removeUpdatingFromState(ids: number[]) {
    this.state.updatingReservations$.next(this.state.updatingReservations$.getValue().filter(x => !ids.includes(x)));
  }

  private getReservations(d: IInitData) {
    const orgId = this.selectedOrgService.getCurrentValue();
    if (!orgId) return of();
    return this.reservationsService.getForTableByOrg({ ...d, orgId }).pipe(
      take(1),
      tap(x => {

      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
